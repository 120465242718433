import "jquery";
import "bootstrap-slider";

import "@microsoft/dotnet-js-interop";

interface DotNetObject {
    invokeMethod<T>(methodIdentifier: string, ...args: any[]): T;

    invokeMethodAsync<T>(methodIdentifier: string, ...args: any[]): Promise<T>;
}

namespace GN2_8100.Client.Shared.Components {
    export class Slider {
        Element: HTMLElement;
        DotNetInstance: DotNetObject;

        constructor(elem: HTMLElement, dotNetInstance: DotNetObject) {
            this.Element = elem.firstElementChild as HTMLElement;
            this.DotNetInstance = dotNetInstance;

            this.Element.style.width = '15rem';

            var jqElement = $(this.Element);

            var slider = jqElement.slider();

            slider.on('slide', e => this.DotNetInstance.invokeMethod('SetValue', e.value));
        }
    }
}

declare global {
    // explicitly add componentFunctions to the existing Window interface
    interface Window { componentFunctions: any; }
}

var component: GN2_8100.Client.Shared.Components.Slider = null;

window.componentFunctions["initSlider"] =
    (elem: HTMLElement, instance: DotNetObject) => {
        if (component != null)
            return;

        component = new GN2_8100.Client.Shared.Components.Slider(elem, instance);

        return true;
    };

window.componentFunctions["disposeSlider"] =
    () => {
        component = null;

        return true;
    }

